var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("ul", { attrs: { id: "StickyEF" } }, [
          _c("li", [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-addEF rounded-pillEF float-right mr-1 mt-1",
                attrs: { type: "button", disabled: _vm.disabledAdd },
                on: { click: _vm.addBtn }
              },
              [_c("i", { staticClass: "fas fa-plus text-white" })]
            )
          ]),
          _c("li", [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-editEF rounded-pillEF float-right mr-1 mt-1",
                attrs: { type: "button", disabled: _vm.disabledEdit },
                on: { click: _vm.editBtn }
              },
              [_c("i", { staticClass: "fas fa-pencil-alt text-white" })]
            )
          ]),
          _c("li", [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-deleteEF rounded-pillEF float-right mr-1 mt-1",
                attrs: { type: "button", disabled: _vm.disabledEdit },
                on: { click: _vm.deleteVisitor }
              },
              [_c("i", { staticClass: "fas fa-trash-alt text-white" })]
            )
          ])
        ]),
        _c("h4", { staticClass: "card-title" }, [_vm._v("Details ")]),
        _c("fieldset", { attrs: { disabled: _vm.disabled } }, [
          _c(
            "form",
            {
              staticClass: "needs-validation",
              attrs: { enctype: "multipart/form-data" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.formSubmit($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-password-input" } }, [
                      _vm._v("Entreprise")
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.Visitor_info.Visitor_company_id,
                            expression: "Visitor_info.Visitor_company_id"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted &&
                            _vm.$v.Visitor_info.Visitor_company_id.$error
                        },
                        on: {
                          blur: _vm.$v.Visitor_info.Visitor_company_id.$error,
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.Visitor_info,
                              "Visitor_company_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { selected: "", value: "0" } }, [
                          _vm._v("Selectionner une entreprise")
                        ]),
                        _vm._l(this.companys, function(company) {
                          return _c(
                            "option",
                            {
                              key: company.id,
                              domProps: { value: company.id }
                            },
                            [_vm._v(" " + _vm._s(company.company_name) + " ")]
                          )
                        })
                      ],
                      2
                    ),
                    _vm.submitted &&
                    _vm.$v.Visitor_info.Visitor_company_id.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.Visitor_info.Visitor_company_id.required
                            ? _c("span", [_vm._v("Le champ est obligatoire.")])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-inputCity" } }, [
                      _vm._v("Nom de visiteur *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Visitor_info.Visitor_fullname,
                          expression: "Visitor_info.Visitor_fullname"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.Visitor_info.Visitor_fullname.$error
                      },
                      attrs: { type: "text", placeholder: "" },
                      domProps: { value: _vm.Visitor_info.Visitor_fullname },
                      on: {
                        blur: _vm.$v.Visitor_info.Visitor_fullname.$error,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.Visitor_info,
                            "Visitor_fullname",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.submitted && _vm.$v.Visitor_info.Visitor_fullname.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.Visitor_info.Visitor_fullname.required
                            ? _c("span", [_vm._v("Le champ est obligatoire.")])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-inputCity" } }, [
                      _vm._v("Numéro de carte d'identitie *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Visitor_info.Visitor_identity_card_number,
                          expression:
                            "Visitor_info.Visitor_identity_card_number"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.Visitor_info.Visitor_identity_card_number
                            .$error
                      },
                      attrs: { type: "text", placeholder: "" },
                      domProps: {
                        value: _vm.Visitor_info.Visitor_identity_card_number
                      },
                      on: {
                        blur:
                          _vm.$v.Visitor_info.Visitor_identity_card_number
                            .$error,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.Visitor_info,
                            "Visitor_identity_card_number",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.submitted &&
                    _vm.$v.Visitor_info.Visitor_identity_card_number.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.Visitor_info.Visitor_identity_card_number
                            .required
                            ? _c("span", [_vm._v("Le champ est obligatoire.")])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Nationalité *")
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.Visitor_info.nationality,
                            expression: "Visitor_info.nationality"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted &&
                            _vm.$v.Visitor_info.nationality.$error,
                          "isDisabled disabledBG": _vm.disabled
                        },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.Visitor_info,
                              "nationality",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.nationalitys, function(nat) {
                        return _c(
                          "option",
                          { key: nat.uuid, domProps: { value: nat.uuid } },
                          [_vm._v(" " + _vm._s(nat.designation) + " ")]
                        )
                      }),
                      0
                    ),
                    _vm.submitted && _vm.$v.Visitor_info.nationality.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.Visitor_info.nationality.required
                            ? _c("span", [_vm._v(" Le champ est obligatoire.")])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-password-input" } }, [
                      _vm._v("Fonction")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Visitor_info.Visitor_function,
                          expression: "Visitor_info.Visitor_function"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.Visitor_info.Visitor_function.$error
                      },
                      attrs: { type: "text", placeholder: "" },
                      domProps: { value: _vm.Visitor_info.Visitor_function },
                      on: {
                        blur: _vm.$v.Visitor_info.Visitor_function.$error,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.Visitor_info,
                            "Visitor_function",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.submitted && _vm.$v.Visitor_info.Visitor_function.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.Visitor_info.Visitor_function.required
                            ? _c("span", [_vm._v("La champ est obligatoire.")])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-md-4 col-lg-2 mt-4" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "mb-3",
                        attrs: { switch: "", value: 1, checked: "checked" },
                        model: {
                          value: _vm.Visitor_info.active,
                          callback: function($$v) {
                            _vm.$set(_vm.Visitor_info, "active", $$v)
                          },
                          expression: "Visitor_info.active"
                        }
                      },
                      [_vm._v("Visiteur Active")]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabled,
                        expression: "!disabled"
                      }
                    ],
                    staticClass: "col-md-12"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light btn-label float-right ml-2",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelBtn }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "fas fa-times-circle text-danger label-icon"
                        }),
                        _vm._v(" Annuler ")
                      ]
                    ),
                    _vm._m(0)
                  ]
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-label btn-primary float-right",
        attrs: { type: "submit" }
      },
      [
        _c("i", { staticClass: "far fa-save label-icon " }),
        _vm._v(" Enregistrer ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }